<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_province">Filter By Province</label>
            <v-select
              id="filter_province"
              v-model="selected.province"
              :options="list.provinces"
              label="province_name"
              placeholder="-- Please Select Province --"
            >
              <template #option="{ province_name }">
                <span>{{ province_name }}</span>
              </template>
              <template #no-options="">
                No Available Provinces
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_municipality">Filter By Municipality</label>
            <v-select
              id="filter_municipality"
              v-model="selected.municipality"
              :options="list.municipalities"
              label="municipality_name"
              placeholder="-- Please Select Municipality --"
            >
              <template #option="{ municipality_name }">
                <span>{{ municipality_name }}</span>
              </template>
              <template #no-options="">
                No Available Municipalites
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Lead Tag</label>
            <b-select
              v-model="filter.lead_tag"
              :options="filterLeadTags"
              @change="onTableRefresh"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap text-center">
            <b-button
              size="sm"
              class="mr-1"
              @click="onShowDetailForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-view-detail"
      size="xl"
      title="View Details"
      scrollable
      no-close-on-backdrop
    >
      <b-tabs>
        <b-tab
          title="Customer Information"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="first_name">First Name</label>
                <b-input
                  id="first_name"
                  v-model="customer.first_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="last_name">Last Name</label>
                <b-input
                  id="last_name"
                  v-model="customer.last_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="mobile_number">Mobile Number</label>
                <b-input
                  id="mobile_number"
                  v-model="customer.mobile_number"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="gender">Gender</label>
                <b-input
                  id="gender"
                  v-model="customer.gender"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="region_name">Region</label>
                <b-input
                  id="region_name"
                  v-model="customer.region_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="province_name">Province</label>
                <b-input
                  id="province_name"
                  v-model="customer.province_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="municipality_name">Municipality</label>
                <b-input
                  id="municipality_name"
                  v-model="customer.municipality_name"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-form-group>
                <label for="barangay_name">Barangay</label>
                <b-input
                  id="barangay_name"
                  v-model="customer.barangay_name"
                  disabled
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Communication Logs"
        >
          <b-table
            ref="communicationLogTable"
            hover
            responsive
            class="mt-2"
            :per-page="tableCSLogSettings.perPage"
            :current-page="tableCSLogSettings.currentPage"
            :items="myLogProvider"
            :fields="tableCSLogSettings.fields"
            :sort-by.sync="tableCSLogSettings.sortBy"
            :sort-desc.sync="tableCSLogSettings.sortDesc"
            :sort-direction="tableCSLogSettings.sortDirection"
            :filter="tableCSLogSettings.filter"
            :filter-included-fields="tableCSLogSettings.filterOn"
            show-empty
          >

            <template #cell(index)="data">
              {{ tableCSLogSettings.currentPage * tableCSLogSettings.perPage - tableCSLogSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onViewCommunicationLog(row.item)"
                >
                  View
                </b-button>
              </div>
            </template>

          </b-table>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableCSLogSettings.perPage"
                  :options="tableCSLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableCSLogSettings.currentPage"
                :total-rows="tableCSLogSettings.totalRows"
                :per-page="tableCSLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          title="Visitation Logs"
        >
          <b-table
            ref="visitationLogTable"
            hover
            responsive
            class="mt-2"
            :per-page="tableVisitationLogSettings.perPage"
            :current-page="tableVisitationLogSettings.currentPage"
            :items="myVisitationLogProvider"
            :fields="tableVisitationLogSettings.fields"
            :sort-by.sync="tableVisitationLogSettings.sortBy"
            :sort-desc.sync="tableVisitationLogSettings.sortDesc"
            :sort-direction="tableVisitationLogSettings.sortDirection"
            :filter="tableVisitationLogSettings.filter"
            :filter-included-fields="tableVisitationLogSettings.filterOn"
            show-empty
          >

            <template #cell(index)="data">
              {{ tableVisitationLogSettings.currentPage * tableVisitationLogSettings.perPage - tableVisitationLogSettings.perPage + (data.index + 1) }}
            </template>

            <template #cell()="data">
              <div class="text-nowrap">
                {{ data.value }}
              </div>
            </template>

            <template #cell(actions)="row">
              <div class="text-nowrap">
                <b-button
                  size="sm"
                  @click="onViewVisitationLog(row.item)"
                >
                  View
                </b-button>
              </div>
            </template>

          </b-table>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <div class="w-100 w-sm-25 mb-2 sm-mb-2">
                <b-select
                  v-model="tableVisitationLogSettings.perPage"
                  :options="tableVisitationLogSettings.pageOptions"
                  size="sm"
                />
              </div>
            </b-col>
            <b-col
              cols="12"
              sm="6"
              class="d-flex justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="tableVisitationLogSettings.currentPage"
                :total-rows="tableVisitationLogSettings.totalRows"
                :per-page="tableVisitationLogSettings.perPage"
                first-number
                last-number
                pills
                prev-text="Prev"
                next-text="Next"
                aria-controls="table"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-communication-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposed At</label>
            <b-input
              :value="dateTimeFormatter(selected.log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Agent</label>
            <b-input
              :value="selected.log?.agent_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Channel</label>
            <b-input
              :value="selected.log?.channel"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Call Status</label>
            <b-input
              :value="selected.log?.call_status"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Unsuccessful Reason</label>
            <b-input
              :value="selected.log?.unsuccessful_reason_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition Type</label>
            <b-input
              :value="selected.log?.disposition_type_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Disposition</label>
            <b-input
              :value="selected.log?.disposition_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-view-visitation-log"
      title="View Details"
      size="lg"
      scrollable
      no-close-on-backdrop
    >
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Visited At</label>
            <b-input
              :value="dateTimeFormatter(selected.visitation_log?.created_at)"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>FSO</label>
            <b-input
              :value="selected.visitation_log?.user_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Purpose</label>
            <b-input
              :value="selected.visitation_log?.purpose_name"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group>
            <label>Remarks</label>
            <b-textarea
              :value="selected.visitation_log?.remarks"
              rows="10"
              max-rows="10"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { LeadSupervisorMasterDataService, SharedLogService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'LeadSupervisorMasterData',

  middleware: ['auth', 'lead-supervisor'],

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Master Data'
    }
  },

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        province: 'all',
        municipality: 'all',
        lead_tag: 'all'
      },
      list: {
        purposes: [],
        provinces: [],
        municipalities: [],
        lead_tags: []
      },
      selected: {
        log: null,
        visitation_log: null,
        province: null,
        municipality: null
      },
      customer: {
        customer_type: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_number: '',
        gender: '',
        cluster_name: '',
        area_name: '',
        region_name: '',
        province_name: '',
        municipality_name: '',
        barangay_name: '',
        farm_address: '',
        logs: []
      },
      visitationLog: {
        customer: 0,
        purpose: null,
        remarks: ''
      },
      callRequest: {
        customer: null,
        customer_name: null,
        mobile_number: null,
        remarks: null
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'lead_tag.tag_name', label: 'lead tag' },
          { key: 'first_name' },
          { key: 'last_name' },
          { key: 'mobile_number' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'barangay.barangay_name', label: 'barangay' },
          { key: 'raiser_category_one.category_name', label: 'hog raiser' },
          { key: 'raiser_category_two.category_name', label: 'poultry raiser' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableCSLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'agent_name', label: 'agent' },
          { key: 'channel' },
          { key: 'call_status' },
          { key: 'unsuccessful_reason_name', label: 'unsuccessful reason' },
          { key: 'disposition_type_name', label: 'disposition type' },
          { key: 'disposition_name', label: 'disposition' }
        ]
      },
      tableVisitationLogSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'visitation date', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'fso' },
          { key: 'purpose_name', label: 'purpose' }
        ]
      }
    }
  },

  computed: {
    filterLeadTags () {
      // const leadTags = [1, 3]
      // return [{ value: 'all', text: 'All' }].concat(this.list.lead_tags.filter(item => leadTags.includes(item.value)))
      return [{ value: 'all', text: 'All' }].concat(this.list.lead_tags)
    }
  },

  watch: {
    'selected.province' (value) {
      this.filter.province = value?.id || 'all'
      this.getMunicipalities(value?.id || null)
      this.onFilterChanged()
    },

    'selected.municipality' (value) {
      this.filter.municipality = value?.id || 'all'
      this.onFilterChanged()
    }
  },

  mounted () {
    core.index()
    this.getVisitationPurposes()
    this.getProvinces()
    this.getFilterLeadTags()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await LeadSupervisorMasterDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_province: this.filter.province,
            filter_municipality: this.filter.municipality,
            filter_leadTag: this.filter.lead_tag
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getCustomerLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.customer.id
          })
        )
        this.tableCSLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async myVisitationLogProvider (ctx) {
      try {
        const { data } = await SharedLogService.getVisitationLogs(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            customer_id: this.customer.id
          })
        )
        this.tableVisitationLogSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getVisitationPurposes () {
      await SharedListService.getVisitationPurposes().then(({ data }) => {
        this.list.purposes = data.map(item => {
          return {
            value: item.id,
            text: item.purpose_name
          }
        })
      })
    },

    async getProvinces () {
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality = null
      if (province) {
        this.list.municipalities = []
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities = data
        })
      } else {
        this.list.municipalities = []
      }
    },

    async getFilterLeadTags () {
      await SharedListService.getLeadTags().then(({ data }) => {
        this.list.lead_tags = data.map(({ id, tag_name }) => ({
          text: tag_name,
          value: id
        }))
      })
    },

    onShowDetailForm (current) {
      this.customer.id = current.id
      this.customer.customer_type = current.customer_type
      this.customer.first_name = current.first_name
      this.customer.middle_name = current.middle_name
      this.customer.last_name = current.last_name
      this.customer.mobile_number = current.mobile_number
      this.customer.gender = current.gender
      this.customer.cluster_name = current?.cluster?.cluster_name
      this.customer.area_name = current.area?.area_name
      this.customer.region_name = current.region?.region_name
      this.customer.province_name = current.province?.province_name
      this.customer.municipality_name = current.municipality?.municipality_name
      this.customer.barangay_name = current.barangay?.barangay_name
      this.customer.farm_address = current.farm_address
      this.customer.logs = current.logs
      this.$bvModal.show('modal-view-detail')
    },

    onViewCommunicationLog (current) {
      this.selected.log = { ...current }
      this.$bvModal.show('modal-view-communication-log')
    },

    onViewVisitationLog (current) {
      this.selected.visitation_log = { ...current }
      this.$bvModal.show('modal-view-visitation-log')
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>
